import toast from "react-hot-toast"
import { apiConnector } from "../apiConnection"

import { allInstructor } from "../../services/apis"

const {ALL_INSTRUCTOR_API} = allInstructor;

export const fetchAllInstructor = async (courseId) => {
    const toastId = toast.loading("Loading...")
    //   dispatch(setLoading(true));
    let result = null
    try {
      const response = await apiConnector("GET", ALL_INSTRUCTOR_API);
      console.log("COURSE_DETAILS_API API RESPONSE............", response)
  
      if (!response.data.success) {
        throw new Error(response.data.message)
      }
      result = response.data
    } catch (error) {
      console.log("COURSE_DETAILS_API API ERROR............", error)
      result = error.response.data
      // toast.error(error.response.data.message);
    }
    toast.dismiss(toastId)
    //   dispatch(setLoading(false));
    return result
  }
  