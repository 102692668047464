export const NavbarLinks = [
  {
    title: "Home",
    path: "/",
  },
  {
    title:"Courses",
    path:"/AllCourses"
  },
    {
    title: "Instructor",
    path: "/All-Instructor",
  },
  {
    title: "Catalog",
    // path: '/catalog',
  },
  {
    title: "About Us",
    path: "/about",
  },
  {
    title: "Contact Us",
    path: "/contact",
  },
  ];
